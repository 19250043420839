.home-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-circle-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 210px;
  height: 210px;
  background-color: #ebebeb;
  border: 7px solid #dddddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main-circle .content {
  padding: 10px;
}

.small-circles {
  position: relative;
  width: 100%;
  height: 100%;
}

.small-circle {
  position: absolute;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
}

.small-circle:nth-child(1) {
  top: calc(45% - 210px);
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-color: #89acf5;
  box-shadow: 0 4px 8px rgba(79, 79, 79, 0.1);
  border: 4px solid #89acf5;
}

.small-circle:nth-child(2) {
  top: calc(46% - 150px);
  left: calc(54% + 150px);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-color: #f6c8fb;
  border: 4px solid #f6c8fb;
}

.small-circle:nth-child(3) {
  top: 50%;
  left: calc(55% + 210px);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-color: #d3a97a;
  border: 4px solid #d3a97a;
}

.small-circle:nth-child(4) {
  top: calc(53% + 150px);
  left: calc(55% + 150px);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-color: #b0d2aa;
  border: 4px solid #b0d2aa;
}

.small-circle:nth-child(5) {
  top: calc(55% + 210px);
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-color: #7a6ee4;

  border: 4px solid #7a6ee4;
}

.small-circle:nth-child(6) {
  top: calc(55% + 150px);
  left: calc(45% - 150px);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-color: #f8d370;
  border: 4px solid #f8d370;
}

.small-circle:nth-child(7) {
  top: 50%;
  left: calc(45% - 210px);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-color: #ee7c65;
  border: 4px solid #ee7c65;
}

.small-circle:nth-child(8) {
  top: calc(46% - 150px);
  left: calc(45% - 150px);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-color: #4da9be;
  border: 4px solid #4da9be;
}
