.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.footer-address {
  padding: 10px;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

.footer-address span {
  display: block;
  margin-bottom: 3px;
}

.break-word {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
}

.footer-address span:last-child {
  margin-bottom: 0;
}
