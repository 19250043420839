.custom-col {
  width: 150px !important;
}
.custom-col-s {
  width: 150px;
}
.custom-col-r {
  width: 90px !important;
}

.custom-col-l {
  width: 180px !important;
}

.invoce-header {
  background-color: rgb(126, 169, 255) !important;
  color: red !important;
}

.pil h6 {
  color: red;
}

.rotate {
  width: 20px;
  height: 20px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.glass-button {
  background-color: rgba(50, 0, 100, 0.7); /* Transparent white */
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}
button {
  border-color: rgba(50, 0, 100, 0.7);
  border-radius: 5px; /* Ensure this property is correctly applied */
  border-width: 1px;
  color: rgba(50, 0, 100, 0.7);
  padding: 7px 12px;
  font-size: 16px;
  border-radius: 7px !important; /* Ensure this property is correctly applied */
  cursor: pointer;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  background-color: transparent; /* Set background color to transparent */
}

.glass-button:hover {
  background-color: rgba(
    144,
    0,
    160,
    0.9
  ); /* Slightly more opaque white on hover */
  color: white;
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.15);
}

button:hover {
  background-color: rgba(
    144,
    0,
    160,
    0.9
  ); /* Slightly more opaque white on hover */
  color: white;
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.15);
}

.standard button {
  border-color: rgba(5, 0, 100, 0.7);
  border-radius: 5px; /* Ensure this property is correctly applied */
  border-width: 1px;
  color: rgba(50, 0, 100, 0.7);
  padding: 7px 12px;
  font-size: 16px;
  border-radius: 7px !important; /* Ensure this property is correctly applied */
  cursor: pointer;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  background-color: transparent; /* Set background color to transparent */
}

.standard button:hover {
  background-color: rgba(
    76,
    22,
    255,
    0.9
  ) !important ; /* Slightly more opaque white on hover */
  color: white;
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.15);
}

.custom-toast-icon {
  color: rgba(50, 0, 100, 0.7);
}

/* Style the custom radio button when it's checked */
Input[type="radio"]:checked {
  background-color: rgba(144, 0, 160, 0.9);
}

.paupet-button {
  /* color: #4900FF; */
  border-color: #4900ff !important;
  border-radius: 5px; /* Ensure this property is correctly applied */
  border-width: 1px;
  color: rgba(50, 0, 100, 0.7) !important;
  padding: 7px 12px;
  font-size: 16px;
  border-radius: 7px !important; /* Ensure this property is correctly applied */
  cursor: pointer;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  background-color: transparent !important; /* Set background color to transparent */
}
.paupet-button:hover {
  background-color: #4900ff !important;
  color: white !important;
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.15);
}
.table-container {
  position: relative;
  overflow-y: auto;
  max-height: 500px; /* Set the max height as needed */
}

.stky {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
}

/* Polyfill for IE and Edge browsers */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  table th {
    position: relative;
  }
}

.bill-terms {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2; /* Adjust as needed */
}

.paupet-header {
  background-color: rgba(45, 43, 43, 0.5) !important;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.hidden-for-pdf {
  position: absolute;
  left: -9999px;
}
.modal-content-loading {
  background: transparent;
  border: none;
}

.modal-backdrop.show {
  opacity: 0.5;
  background-color: black;
}
